import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../model/Firebase';
import { displayError } from '../../../model/helpers';

import Answerable from './Answerable/Answerable';
import TestPopUp from './TestPopUp/TestPopUp';

import Logo2 from '../../../assets/Logo2.png';

import './Test.css';

class Test extends React.Component {

  static propTypes = {
    test: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    setOnTest: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      time: 2700000,
      answers: this.emptyAnswers(),
      location: { type: 'audioQuestions', n: 1 },
      saving: false,
      popUp: ''
    }
  }

  emptyAnswers = () => {
    const audioQuestions = {};
    const textQuestions = {};

    for (let i = 1; i <= 50; i++) {
      audioQuestions[i] = {
        correct: false,
        i: null
      };
    }
    for (let i = 1; i <= 50; i++) {
      textQuestions[i] = {
        correct: false,
        i: null
      };
    }

    return { audioQuestions, textQuestions };
  }

  componentDidMount = () => this.timer = setInterval(this.updateTime, 1000);

  componentWillUnmount = () => clearInterval(this.timer);

  updateTime = () => {
    const { time } = this.state;
    const newTime = time - 1000;
    if (time <= 0) {
      this.submit();
      this.setPopUp('done');
    } else {
      this.setState({ time: newTime });
    }
  }

  defineQuestion = () => {
    const { test } = this.props;
    const { location } = this.state;
    return test.data()[location.type][location.n];
  }

  answer = a => {
    const { answers, location } = this.state;
    const { type, n } = location;
    this.setState({ answers: { ...answers, [type]: { ...answers[type], [n]: a } } });
  }

  currentAnswer = () => {
    const { answers, location } = this.state;
    const typeSet = answers[location.type];
    if (typeSet) {
      let answer = typeSet[location.n];
      if (answer) {
        return answer;
      }
    }
    return null;
  }

  next = () => {
    const { type, n } = this.state.location;
    if (n === 50) {
      if (type === 'textQuestions') {
        this.submit();
        this.setPopUp('complete');
      } else {
        this.setState({ location: { type: 'textQuestions', n: 1 } });
      }
    } else {
      this.setState({ location: { type, n: n + 1 } });
    }
  }

  submit = () => {
    const { id } = this.props;
    const { audioQuestions, textQuestions } = this.state.answers;
    this.setState({ saving: true }, () => {
      clearInterval(this.timer);
      Firebase.firestore.doc(`userAnswers/${id}`).set({ audioQuestions, textQuestions })
        .catch(error => displayError(error));
    });
  }

  setPopUp = popUp => this.setState({ popUp });

  render() {
    const { setOnTest } = this.props;
    const { time, location, saving, popUp } = this.state;
    return (
      <div id="Test">
        <img src={Logo2} alt="" />
        <Answerable
          key={location.n}
          time={time}
          n={location.n}
          question={this.defineQuestion()}
          audio={location.type === 'audioQuestions'}
          answer={this.answer}
          selected={this.currentAnswer()}
          next={this.next}
          saving={saving}
        />
        {popUp && <TestPopUp type={popUp} setOnTest={setOnTest} />}
      </div>
    );
  }

}

export default Test;
