import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import deepEqual from 'deep-equal';
import flatulence from 'flatulence';
import Textarea from 'react-textarea-autosize';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import Option from './Option/Option';
import Button from '../../../shared/Button/Button';

import './Question.css';

class Question extends React.Component {

  static propTypes = {
    n: PropTypes.string.isRequired,
    audio: PropTypes.bool,
    question: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      question: props.question,
      savingAudio: false,
      loading: false
    }
  }

  makeResourceInput = () => {
    const { n, audio } = this.props;
    const { question, savingAudio } = this.state;
    if (audio) {
      return (
        <div className="audioInput">
          <label htmlFor="file">{n}.mp3</label>
          <input type="file" id="file" onChange={e => this.setAudioFile(e.target.files)} />
          <i className={`material-icons saving ${savingAudio}`}>{savingAudio ? 'rotate_right' : 'check'}</i>
        </div>
      );
    } else {
      return <Textarea value={question.text} placeholder="Texto..." onChange={e => this.setQuestionField('text', e.target.value)} />;
    }
  }

  setAudioFile = files => {
    const { n } = this.props;
    const file = files[0];
    this.setState({ savingAudio: true }, () => {
      Firebase.storage.ref(`${n}.mp3`).put(file)
        .then(() => this.setState({ savingAudio: false }))
        .catch(error => displayError(error));
    });
  }

  setQuestionField = (path, value) => {
    const { question } = this.state;
    const newQuestion = update(question, flatulence.unflatten({ [`${path}.$set`]: value }));
    this.setState({ question: newQuestion });
  }

  canSave = () => {
    const { audio } = this.props;
    if (audio) {
      const { question: propsQuestion } = this.props;
      const { question: stateQuestion } = this.state;
      const { instructions, options } = stateQuestion;
      return (!deepEqual(propsQuestion, stateQuestion)) && (!!(instructions && options[0] && options[1] && options[2]));
    } else {
      const { question: propsQuestion } = this.props;
      const { question: stateQuestion } = this.state;
      const { instructions, text, options } = stateQuestion;
      return (!deepEqual(propsQuestion, stateQuestion)) && (!!(instructions && text && options[0] && options[1] && options[2]));
    }
  }

  save = () => {
    const { n, audio } = this.props;
    const { question } = this.state;
    const typeSet = audio ? 'audioQuestions' : 'textQuestions';
    this.setState({ loading: true }, () => {
      Firebase.firestore.doc('meta/test').update({ [`${typeSet}.${n}`]: question })
        .then(() => this.setState({ loading: false }))
        .catch(error => displayError(error));
    });
  }

  render() {
    const { n } = this.props;
    const { question, loading } = this.state;
    return (
      <div className="Question">
        <p>{n}</p>
        <div>
          <Textarea value={question.instructions} placeholder="Instrucciones..." onChange={e => this.setQuestionField('instructions', e.target.value)} />
          {this.makeResourceInput()}
          {question.options.map((option, i) => <Option key={i} index={i} option={option} answer={question.answer === i} setQuestionField={this.setQuestionField} />)}
          <Button title="Guardar" action={this.save} disabled={!this.canSave()} loading={loading} />
        </div>
      </div>
    );
  }

}

export default Question;
