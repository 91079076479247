import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../model/Firebase';
import { displayError } from '../../../model/helpers';

import Member from './Member/Member';
import MemberForm from './MemberForm/MemberForm';

import './Team.css';

class Team extends React.Component {

  static propTypes = {
    id: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.state = {
      team: [],
      adding: false
    }
  }

  componentDidMount = () => {
    this.disconnectTeam = Firebase.firestore.collection('team').onSnapshot(
      querySnapshot => this.setState({ team: querySnapshot.docs }),
      error => displayError(error)
    );
  }

  componentWillUnmount = () => {
    if (this.disconnectTeam) {
      this.disconnectTeam();
    }
  }

  render() {
    const { id } = this.props;
    const { team, adding } = this.state;
    return (
      <div id="Team">
        {team.filter(member => member.id !== id).map(member => <Member key={member.id} member={member} />)}
        <button onClick={() => this.setState({ adding: true })}>
          <i className="material-icons">add</i>
        </button>
        {adding && <MemberForm close={() => this.setState({ adding: false })} />}
      </div>
    );
  }

}

export default Team;
