export function displayError(error) {
  let value = '';
  if (typeof error === 'string') {
    value = error;
  } else {
    switch (error.code) {
      case 'auth/invalid-email':
        value = 'Este correo no es valido.';
        break;
      case 'auth/wrong-password':
        value = 'Contraseña incorrecta.';
        break;
      case 'auth/invalid-password':
        value = 'La contraseña debe tener al menos 6 caracteres.';
        break;
      case 'auth/user-not-found':
        value = 'Este usuario no está registrado.';
        break;
      case 'auth/requires-recent-login':
        value = 'Esta operación es sensible y requiere autenticación reciente. Por favor reinicia sesión e intenta de nuevo.';
        break;
      case 'auth/weak-password':
        value = 'La contraseña debe tener al menos 6 caracteres.';
        break;
      case 'auth/user-token-expired':
        value = 'Tus credenciales de seguridad han cambiado. Por favor reinicia sesión.';
        break;
      case 'auth/email-already-in-use':
        value = 'Este correo ya está siendo utilizado por otra cuenta.';
        break;
      case 'storage/object-not-found':
        value = 'Archivo de audio no encontrado.';
        break;
      default:
        console.error(error);
        value = 'Algo salió mal. Intenta de nuevo. Si los problemas persisten, contactanos.';
        break;
    }
  }
  alert(`Error: ${value}`);
}
