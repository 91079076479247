import React from 'react';
import PropTypes from 'prop-types';

import './Info.css';

class Info extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  };

  render() {
    const { name, value } = this.props;
    if (value) {
      return (
          <div className="Info">
            <p>{name}</p>
            <p>{value}</p>
          </div>
      );
    } else {
      return null;
    }
  }

}

export default Info;
