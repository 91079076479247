import React from 'react';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import User from './User/User';
import UserDetail from './UserDetail/UserDetail';

import './UserSearch.css';

class UserSearch extends React.Component {

  constructor() {
    super();
    this.state = {
      query: '',
      users: [],
      canShowMore: false,
      user: null
    }
  }

  componentDidMount = () => this.getOrderedUsers();

  getOrderedUsers = () => {
    this.setState({ users: [] }, () => {
      Firebase.firestore.collection('users').orderBy('registered', 'desc').limit(15).get()
        .then(querySnapshot => {
          const users = querySnapshot.docs;
          const canShowMore = users.length === 18;
          this.setState({ users, canShowMore });
        })
        .catch(error => displayError(error));
    });
  }

  setQuery = query => {
    if (query) {
      this.setState({ query }, this.executeQuery);
    } else {
      this.setState({ query }, this.getOrderedUsers);
    }
  }

  executeQuery = () => {
    const { query } = this.state;
    this.setState({ users: [], canShowMore: false }, () => {
      Firebase.firestore.doc(`users/${query}`).get()
        .then(documentSnapshot => {
          if (documentSnapshot.exists) {
            this.setState({ users: [documentSnapshot] });
          }
        })
        .catch(error => displayError(error));
    });
  }

  showMore = () => {
    const { users } = this.state;
    const last = users[users.length - 1];
    Firebase.firestore.collection('users').orderBy('registered').startAfter(last).limit(18).get()
      .then(querySnapshot => {
        const newUsers = querySnapshot.docs;
        const canShowMore = newUsers.length === 18;
        this.setState({ users: users.concat(newUsers), canShowMore });
      })
      .catch(error => displayError(error));
  }

  setUser = user => this.setState({ user });

  reload = () => {
    const { query } = this.state;
    if (query) {
      this.executeQuery();
    } else {
      this.getOrderedUsers();
    }
  }

  render() {
    const { query, users, canShowMore, user } = this.state;
    return (
      <div id="UserSearch">
        <header>
          <input type="text" value={query} onChange={e => this.setQuery(e.target.value)} placeholder="Búsqueda por cédula..." />
          <button disabled={query === ''} onClick={() => this.setQuery('')}>
            <i className="material-icons">close</i>
          </button>
        </header>
        <main>
          {users.map(user => <User key={user.id} user={user.data()} set={this.setUser} />)}
        </main>
        {canShowMore &&
          <button onClick={this.showMore}>
            <i className="material-icons">add</i>
          </button>
        }
        {user && <UserDetail user={user} reload={this.reload} close={() => this.setUser(null)} />}
      </div>
    );
  }

}

export default UserSearch;
