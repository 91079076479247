import React from 'react';

import Firebase from '../../../model/Firebase';
import { displayError } from '../../../model/helpers';

import Question from './Question/Question';

import './Exam.css';

class Exam extends React.Component {

  constructor() {
    super();
    this.state = {
      test: null
    }
  }

  componentDidMount = () => {
    this.disconnectMeta = Firebase.firestore.collection('meta').onSnapshot(
      querySnapshot => {
        const test = querySnapshot.docs.find(doc => doc.id === 'test').data();
        this.setState({ test });
      },
      error => displayError(error)
    );
  }

  componentWillUnmount = () => {
    if (this.disconnectMeta) {
      this.disconnectMeta();
    }
  }

  render() {
    const { test } = this.state;
    if (test) {
      return (
        <div id="Exam">
          {Object.keys(test.audioQuestions).map(n => <Question key={n} n={n} audio question={test.audioQuestions[n]} />)}
          {Object.keys(test.textQuestions).map(n => <Question key={n} n={n} question={test.textQuestions[n]} />)}
        </div>
      );
    } else {
      return null;
    }
  }

}

export default Exam;
