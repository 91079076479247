import React from 'react';

import Firebase from '../../model/Firebase';
import { displayError } from '../../model/helpers';

import Init from './Init/Init';
import Status from './Status/Status';
import Test from './Test/Test';

import './App.css';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      meta: null,
      id: '',
      onTest: false
    }
  }

  componentDidMount = () => {
    Firebase.firestore.collection('meta').get()
      .then(querySnapshot => this.setState({ meta: querySnapshot.docs }))
      .catch(error => displayError(error));
  }

  findMetaDoc = id => {
    const { meta } = this.state;
    return meta.find(doc => doc.id === id);
  }

  setID = id => this.setState({ id });

  setOnTest = onTest => this.setState({ onTest });

  render() {
    const { meta, id, onTest } = this.state;
    if (meta) {
      if (id) {
        if (onTest) {
          return (
            <div id="App">
              <Test test={this.findMetaDoc('test')} id={id} setOnTest={this.setOnTest} />
            </div>
          );
        } else {
          return (
            <div id="App">
              <Status id={id} setID={this.setID} setOnTest={this.setOnTest} />
            </div>
          );
        }
      } else {
        return (
          <div id="App" className="background">
            <Init setID={this.setID} />
          </div>
        );
      }
    } else {
      return null;
    }
  }

}

export default App;
