import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

firebase.initializeApp({
    apiKey: "AIzaSyDYOAhvqQTlxjRwe2_EiNBP7UnK2I8cHKM",
    authDomain: "examen-coningles.firebaseapp.com",
    databaseURL: "https://examen-coningles.firebaseio.com",
    projectId: "examen-coningles",
    storageBucket: "examen-coningles.appspot.com",
    messagingSenderId: "744633526057",
    appId: "1:744633526057:web:f69b84d8ad6b89a0527938"
});

class Firebase {

    static authentication = firebase.auth();
    static firestore = firebase.firestore();
    static storage = firebase.storage();
    static functions = firebase.functions();
    static helper = firebase;

}

export default Firebase;