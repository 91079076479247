import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import './Option.css';

class Option extends React.Component {

  static propTypes = {
    index: PropTypes.number.isRequired,
    option: PropTypes.string.isRequired,
    answer: PropTypes.bool.isRequired,
    setQuestionField: PropTypes.func.isRequired
  };

  render() {
    const { index, option, answer, setQuestionField } = this.props;
    return (
      <div className="Option">
        <Textarea value={option} placeholder={`Opción...`} onChange={e => setQuestionField(`options.${index}`, e.target.value)} />
        <button className={`${answer}`} onClick={() => setQuestionField('answer', index)}>
          <i className="material-icons">{answer ? 'check_box' : 'check_box_outline_blank'}</i>
        </button>
      </div>
    );
  }

}

export default Option;
