import React from 'react';

import App from './App/App';
import Secret from './Secret/Secret';

const Root = () => {
    const [subdomain] = window.location.hostname.split('.');

    if (subdomain === 'examen' || subdomain === 'localhost') {
        return <App />;
    } else if (subdomain === 'secret') {
        return <Secret />;
    } else {
        window.location.replace('https://examen.coningles.com/');
    }
}

export default Root;
