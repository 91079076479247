import React from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';

import Firebase from '../../../../../model/Firebase';
import { displayError } from '../../../../../model/helpers';

import Info from './Info/Info';
import TestResults from './TestResults/TestResults';
import Button from '../../../../shared/Button/Button';

import './UserDetail.css';

const pdfMake = require('pdfmake/build/pdfmake.js');

class UserDetail extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      loading: true,
      answers: null,
      downloading: false,
      opportunifying: false,
      opportunifies: 0
    }
  }

  componentDidMount = () => {
    const { user } = this.props;
    Firebase.firestore.doc(`userAnswers/${user.id}`).get()
      .then(documentSnapshot => {
        const answers = documentSnapshot.exists ? documentSnapshot.data() : null;
        this.setState({ loading: false, answers });
      })
      .catch(error => displayError(error));
  }

  downloadResults = () => {
    const { user } = this.props;
    this.setState({ downloading: true }, () => {
      html2canvas(document.querySelector("#print"))
        .then(canvas => {
          const pdf = { content: { image: canvas.toDataURL(), width: 400, alignment: 'center' } };
          pdfMake.createPdf(pdf).download(user.name, () => this.setState({ downloading: false }));
        });
    });
  }

  opportunify = () => {
    const { user, reload } = this.props;
    const { opportunifies } = this.state;
    this.setState({ opportunifying: true }, () => {
      Firebase.firestore.doc(`users/${user.id}`).update({ opportunities: user.opportunities + opportunifies + 1 })
        .then(() => {
          this.setState({ opportunifying: false, opportunifies: opportunifies + 1 }, reload)
        })
        .catch(error => {
          this.setState({ opportunifying: false });
          displayError(error);
        });
    });
  }

  render() {
    const { user, close } = this.props;
    const { name, id, email, corporateEmail, phone, company, post, address, city, country, executive, opportunities, registered } = user;
    const registeredDate = new Date(registered);
    const { loading, answers, downloading, opportunifying, opportunifies } = this.state;

    return (
      <div id="UserDetail">
        <div className="detail">
          <div id="print">
            <header>
              <p>{name}</p>
              {!downloading &&
                <button onClick={close}>
                  <i className="material-icons">close</i>
                </button>
              }
            </header>
            <div className="info">
              <Info name="Número de Identificación" value={id} />
              <Info name="Correo" value={email} />
              <Info name="Correo Corporativo" value={corporateEmail} />
              <Info name="Empresa" value={company} />
              <Info name="Cargo" value={post} />
              <Info name="Dirección" value={address} />
              <Info name="Ciudad" value={city} />
              <Info name="País" value={country} />
              <Info name="Celular" value={phone} />
              <Info name="Ejecutivo" value={executive} />
              <Info name="Registro" value={`${registeredDate.getFullYear()}-${registeredDate.getMonth() + 1}-${registeredDate.getDate()}`} />
            </div>
            <TestResults answers={answers} loading={loading} />
          </div>
          <footer>
            <Button title="Descargar Resultados" action={this.downloadResults} disabled={!answers} loading={downloading} />
            <Button title={`Dar +1 Oportunidad (${opportunities + opportunifies})`} action={this.opportunify} loading={opportunifying} blue />
          </footer>
        </div>
      </div>
    );
  }

}

export default UserDetail;
