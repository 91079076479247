import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../shared/Button/Button';
import InitForm from './InitForm/InitForm';

import Logo from '../../../assets/Logo.png';
import Logo3 from '../../../assets/Logo3.png';

import './Init.css';

class Init extends React.Component {

  static propTypes = {
    setID: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      form: ''
    }
  }

  setForm = form => this.setState({ form });

  render() {
    const { setID } = this.props;
    const { form } = this.state;
    return (
      <div id="Init">
          <header>
              <img src={Logo} alt="" />
              <img src={Logo3} alt="" />
          </header>
        <p>¿Quieres conocer tu nivel de Inglés?</p>
        <p>Realiza nuestro examen de clasificación para saber en qué nivel debes iniciar tu programa de inglés conversacional y de negocios.</p>
        <div className="stack">
          <Button title="Registro Primera Vez" action={() => this.setForm('signUp')} blue />
          <Button title="Usuarios Registrados" action={() => this.setForm('signIn')} />
        </div>
        {form && <InitForm type={form} setID={setID} close={() => this.setForm('')} />}
      </div>
    );
  }

}

export default Init;
