import React from 'react';
import PropTypes from 'prop-types';

import SetRow from './SetRow/SetRow';

import './AnswerSet.css';

class AnswerSet extends React.Component {

  static propTypes = {
    set: PropTypes.object.isRequired
  };

  countCorrectAnwers = () => {
    const { set } = this.props;
    let count = 0;
    for (let n of Object.keys(set)) {
      if (set[n].correct) {
        count++;
      }
    }
    return count;
  }

  render() {
    const { set } = this.props;
    return (
      <div className="AnswerSet">
        <div>
          {Object.keys(set).map(n => <SetRow key={n} n={n} value={set[n]} />)}
        </div>
        <p>{this.countCorrectAnwers()}</p>
      </div>
    );
  }

}

export default AnswerSet;
