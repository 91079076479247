import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import './Member.css';

class Member extends React.Component {

  static propTypes = {
    member: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
      deleting: false
    }
  }

  toggleAdmin = () => {
    const { member } = this.props;
    const { admin } = member.data();
    member.ref.update({ admin: !admin })
      .catch(error => displayError(error));
  }

  delete = () => {
    const { member } = this.props;
    const { name, email } = member.data();
    if (window.confirm(`Confirma que quieres eliminar a ${name} (${email}) del equipo.`)) {
      this.setState({ deleting: true }, () => {
        const deleteUser = Firebase.functions.httpsCallable('deleteUser');
        deleteUser({ id: member.id })
          .catch(error => {
            displayError({ code: error.message });
            this.setState({ deleting: false });
          });
      });
    }
  }

  render() {
    const { name, email, admin } = this.props.member.data();
    const { deleting } = this.state;
    return (
      <div className="Member">
        <div>
          <p>{name}</p>
          <p>{email}</p>
        </div>
        <div>
          <button className={`admin ${admin}`} onClick={this.toggleAdmin}>
            <i className="material-icons">{admin ? 'check_box' : 'check_box_outline_blank'}</i>
            <p>Administrador</p>
          </button>
          <button className="delete" onClick={this.delete} disabled={deleting}>
            <i className="material-icons">delete_forever</i>
            <p>Eliminar</p>
          </button>
        </div>
      </div>
    );
  }

}

export default Member;
