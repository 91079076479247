import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import Button from '../../../shared/Button/Button';

import './InitForm.css';

class InitForm extends React.Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    setID: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      name: '',
      id: '',
      email: '',
      corporateEmail: '',
      company: '',
      post: '',
      address: '',
      city: '',
      country: '',
      phone: '',
      executive: '',
      loading: false,
      team: []
    }
  }

  componentDidMount = () => {
    Firebase.firestore.collection('team').onSnapshot(
      querySnapshot => this.setState({ team: querySnapshot.docs.map(doc => doc.data()) }),
      error => displayError(error)
    );
  }

  isSigningUp = () => {
    const { type } = this.props;
    return type === 'signUp';
  }

  makeInputs = () => {
    const { name, id, email, corporateEmail, company, post, address, city, country, phone, executive, team } = this.state;
    if (this.isSigningUp()) {
      return (
        <main>
          <input type="text" value={name} placeholder="Nombres y Apellidos" onChange={e => this.setState({ name: e.target.value })} />
          <input type="text" value={id} placeholder="Número de Identificación" onChange={e => this.setState({ id: e.target.value })} />
          <input type="text" value={email} placeholder="Correo Personal" onChange={e => this.setState({ email: e.target.value })} />
          <input type="text" value={corporateEmail} placeholder="Correo Corporativo (opcional)" onChange={e => this.setState({ corporateEmail: e.target.value })} />
          <div>
            <input type="text" value={company} placeholder="Empresa (opcional)" onChange={e => this.setState({ company: e.target.value })} />
            <p>Si perteneces a un convenio indica la empresa.</p>
          </div>
          <input type="text" value={post} placeholder="Cargo (opcional)" onChange={e => this.setState({ post: e.target.value })} />
          <input type="text" value={address} placeholder="Dirección (opcional)" onChange={e => this.setState({ address: e.target.value })} />
          <input type="text" value={city} placeholder="Ciudad" onChange={e => this.setState({ city: e.target.value })} />
          <input type="text" value={country} placeholder="País" onChange={e => this.setState({ country: e.target.value })} />
          <input type="text" value={phone} placeholder="Celular" onChange={e => this.setState({ phone: e.target.value })} />
          <select value={executive} onChange={e => this.setState({ executive: e.target.value })}>
            <option value="" disabled>Ejecutivo que te atendió...</option>
            {team.map((member, i) => <option key={i} value={member.name}>{member.name}</option>)}
            <option value="Ninguno / No recuerdo">Ninguno / No recuerdo</option>
          </select>
        </main>
      );
    } else {
      return (
        <main>
          <input type="text" value={id} placeholder="Número de Identificación" onChange={e => this.setState({ id: e.target.value })} />
        </main>
      );
    }
  }

  canSubmit = () => {
    const { name, id, email, city, country, phone, executive } = this.state;
    if (this.isSigningUp()) {
      return !!(name && id && email && city && country && phone && executive);
    } else {
      return !!id;
    }
  }

  submit = () => {
    const { setID } = this.props;
    const { name, id, email, corporateEmail, company, post, address, city, country, phone, executive } = this.state;
    this.setState({ loading: true }, () => {
      if (this.isSigningUp()) {
        this.checkIDAvailability()
          .then(used => {
            if (used) {
              displayError(`El número de identificación "${id}" ya está en uso.`);
            } else {
              Firebase.firestore.doc(`users/${id}`).set({ name, id, email, corporateEmail, company, post, address, city, country, phone, executive, opportunities: 1, registered: new Date().getTime() })
                .then(() => setID(id))
                .catch(error => displayError(error));
            }
            this.setState({ loading: false });
          });
      } else {
        this.checkIDAvailability()
          .then(used => {
            if (used) {
              setID(id);
            } else {
              displayError(`El número de identificación "${id}" no está registrado.`);
              this.setState({ loading: false });
            }
          });
      }
    });
  }

  checkIDAvailability = () => {
    const { id } = this.state;
    return new Promise((resolve, reject) => {
      Firebase.firestore.doc(`users/${id}`).get()
        .then(documentSnapshot => resolve(documentSnapshot.exists))
        .catch(error => displayError(error));
    });
  }

  render() {
    const { close } = this.props;
    const { loading } = this.state;
    const title = this.isSigningUp() ? 'Registrarse' : 'Ingresar';

    return (
      <div id="InitForm">
        <div className="form">
          <header>
            <p>{title}</p>
            <button onClick={close}>
              <i className="material-icons">close</i>
            </button>
          </header>
          {this.makeInputs()}
          <Button title={title} action={this.submit} disabled={!this.canSubmit()} loading={loading} />
          {this.isSigningUp() && (
              <p>Acepto recibir otras comunicaciones de Coningles y permito el almacenamiento y procesamiento de mis datos personales.</p>
          )}
        </div>
      </div>
    );
  }

}

export default InitForm;
