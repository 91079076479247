import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.css';

class Button extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        blue: PropTypes.bool
    };

    render() {
        const { title, action, disabled, loading, blue } = this.props;

        if (loading) {
            return (
                <button
                    className={classNames('Button', { blue })}
                    disabled>
                    Cargando...
                </button>
            );
        } else {
            return (
                <button
                    className={classNames('Button', { blue })}
                    onClick={action}
                    disabled={disabled}>
                    {title}
                </button>
            );
        }
    }

}

export default Button;
