import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../shared/Button/Button';

import './TestPopUp.css';

class TestPopUp extends React.Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    setOnTest: PropTypes.func.isRequired
  };

  render() {
    const { type, setOnTest } = this.props;
    return (
      <div id="TestPopUp">
        <main>
          <p>{type === 'complete' ? 'Gracias por realizar nuestro examen en línea.' : 'Se te agoto el tiempo del examen'}</p>
          <p>{type === 'complete' ? 'Uno de nuestros asesores se pondrá en contacto contigo para informarte del proceso a seguir.' : 'Recuerda que puedes solicitar más oportunidades para volver a tomarlo.'}</p>
          {type === 'complete' ?
            <a href="https://coningles.com/">Más sobre Coningles</a>
            :
            <Button title="Solicitar mas oportunidades." action={() => setOnTest(false)} />
          }
        </main>
      </div>
    );
  }

}

export default TestPopUp;
