import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../model/Firebase';
import { displayError } from '../../../model/helpers';

import Button from '../../shared/Button/Button';

import './Status.css';

class Status extends React.Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    setID: PropTypes.func.isRequired,
    setOnTest: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      user: null,
      message: '',
      messageSent: false,
      loading: false,
      resigned: false
    }
  }

  componentDidMount = () => {
    const { id } = this.props;
    this.disconnectUser = Firebase.firestore.doc(`users/${id}`).onSnapshot(
      doc => {
        const { user } = this.state;
        if (user) {
          if (!doc.metadata.hasPendingWrites) {
            this.setState({ user: doc.data() });
          }
        } else {
          this.setState({ user: doc.data() });
        }
      },
      error => displayError(error)
    );
  }

  componentWillUnmount = () => this.disconnectUser();

  defineStatus = () => {
    const { opportunities } = this.state.user;
    if (opportunities > 0) {
      if (opportunities > 1) {
        return `Tienes ${opportunities} oportunidades para tomar el examen.`;
      } else {
        return `Tienes 1 oportunidad para tomar el examen.`;
      }
    } else {
      return `No tienes más oportunidades para realizar el examen.`;
    }
  }

  makeOptions = () => {
    const { user, message, messageSent, loading, resigned } = this.state;
    const { opportunities } = user;
    if (resigned) {
      return (
          <main>
            <p className="title">Gracias por realizar nuestro examen en linea.</p>
            <p>Uno de nuestros asesores se pondrá en contacto contigo para informarte del proceso a seguir.</p>
          </main>
      );
    } else {
      if (opportunities > 0) {
        return (
            <main>
              <p>{this.defineStatus()} Las siguientes son las instrucciones que debes tener en cuenta para realizar el examen.</p>
              <ol>
                <li>Debes disponer de 45 minutos de tu tiempo sin interrupciones.</li>
                <li>El examen consta de 100 preguntas de selección múltiple: 50 de comprensión auditiva y 50 de comprensión de lectura y gramática.</li>
                <li>Debes encender el AUDIO y poner un volumen moderado.</li>
                <li>Para escuchar el audio de la pregunta debes dar clic en el botón que dice "Reproducir Audio".</li>
                <li>Para que puedas avanzar a la siguiente pregunta debes escoger alguna de las opciones de respuesta, y dar clic en el botón que dice "Siguiente".</li>
                <li>Para un mejor funcionamiento recomendamos utilizar Google Chrome o navegadores diferentes a Internet Explorer.</li>
                <li>Si consideras que estas en nivel uno (1), no tienes que presentar el examen.</li>
              </ol>
              <div className="stack">
                <Button title="Estoy en nivel 1" action={this.resign} loading={loading} />
                <Button blue title="Comenzar el examen" action={this.beginTest} loading={loading} />
              </div>
            </main>
        );
      } else {
        return (
            <main>
              <p>{this.defineStatus()} Si deseas, puedes solicitar otra oportunidad.</p>
              <textarea value={message} placeholder="Puedes escribir un mensaje para solicitar una oportunidad... (Opcional)" disabled={loading || messageSent} onChange={e => this.setState({ message: e.target.value })}></textarea>
              <Button title={messageSent ? 'Solicitud enviada' : 'Solicita  más oportunidades'} action={this.requestOpportunity} loading={loading} disabled={messageSent} blue={messageSent} />
            </main>
        );
      }
    }
  }

  beginTest = () => {
    const { id, setOnTest } = this.props;
    const { user } = this.state;
    this.setState({ loading: true }, () => {
      Firebase.firestore.doc(`users/${id}`).update({ opportunities: user.opportunities - 1 })
        .then(() => setOnTest(true))
        .catch(error => displayError(error));
    });
  }

  requestOpportunity = () => {
    const { user, message } = this.state;
    const { executive, name, id } = user;
    this.setState({ loading: true }, () => {
      const requestOpportunity = Firebase.functions.httpsCallable('requestOpportunity');
      requestOpportunity({ executive, name, id, message })
        .then(() => this.setState({ messageSent: true, loading: false }))
        .catch(error => {
          displayError(error);
          this.setState({ loading: false });
        });
    });
  }

  resign = () => {
    this.setState({ loading: true }, () => {
      const { id } = this.props;
      const { user } = this.state;
      const batch = Firebase.firestore.batch();
      const audioQuestions = {};
      const textQuestions = {};

      for (let i = 1; i <= 50; i++) {
        audioQuestions[i] = {
          correct: false,
          i: null
        };
      }

      for (let i = 1; i <= 50; i++) {
        textQuestions[i] = {
          correct: false,
          i: null
        };
      }

      batch.set(Firebase.firestore.doc(`userAnswers/${id}`), { audioQuestions, textQuestions });
      batch.update( Firebase.firestore.doc(`users/${id}`), { opportunities: user.opportunities - 1 });

      batch.commit()
          .then(() => this.setState({ resigned: true }))
          .catch(error => displayError(error));
    });
  }

  render() {
    const { setID } = this.props;
    const { user } = this.state;
    if (user) {
      const { name } = user;
      return (
        <div id="Status">
          <p className="title">Bienvenido, {name.split(' ')[0]}.</p>
          <button onClick={() => setID('')}>No eres {name}? Ingresa de nuevo.</button>
          {this.makeOptions()}
        </div>
      );
    } else {
      return null;
    }
  }

}

export default Status;
