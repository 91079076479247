import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../model/Firebase';
import { displayError } from '../../../model/helpers';

import Button from '../../shared/Button/Button';

import './Auth.css';

class Auth extends React.Component {

  static propTypes = {
    ready: PropTypes.bool.isRequired
  };

  constructor() {
    super();
    this.state = {
      email: '',
      pass: '',
      loading: false
    }
  }

  signIn = () => {
    const { email, pass } = this.state;
    this.setState({ loading: true }, () => {
      Firebase.authentication.signInWithEmailAndPassword(email, pass)
        .catch(error => {
          this.setState({ loading: false });
          displayError(error);
        });
    });
  }

  render() {
    const { ready } = this.props;
    const { email, pass, loading } = this.state;
    return (
      <div id="Auth">
        <p className="title">Inicio de Sesión.</p>
        <main>
          <input type="text" value={email} placeholder="Correo" onChange={e => this.setState({ email: e.target.value })} />
          <input type="password" value={pass} placeholder="Contraseña" onChange={e => this.setState({ pass: e.target.value })} />
          <Button title="Entrar" action={this.signIn} disabled={!(email && pass)} loading={!ready || loading} />
        </main>
      </div>
    );
  }

}

export default Auth;
