import React from 'react';
import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom';

import Firebase from '../../model/Firebase';
import { displayError } from '../../model/helpers';

import Auth from './Auth/Auth';
import Home from './Home/Home';
import Exam from './Exam/Exam';
import Team from './Team/Team';

import './Secret.css';

class Secret extends React.Component {

  constructor() {
    super();
    this.state = {
      user: null,
      ready: false
    }
  }

  componentDidMount = () => {
    Firebase.authentication.onAuthStateChanged(user => {
      if (user) {
        this.disconnectUser = Firebase.firestore.doc(`team/${user.uid}`).onSnapshot(
          documentSnapshot => this.setState({ user: documentSnapshot, ready: true }),
          error => displayError(error)
        );
      } else {
        if (this.disconnectUser) {
          this.disconnectUser();
        }
        this.setState({ user: null, ready: true });
      }
    });
  }

  componentWillUnmount = () => {
    if (this.disconnectUser) {
      this.disconnectUser();
    }
  }

  makeHeader = router => {
    const { name } = this.state.user.data();
    const { pathname } = router.location;
    switch (pathname) {
      case '/':
        return (
          <header>
            <div>
              <p className="title">Hola, {name.split(' ')[0]}.</p>
            </div>
            <button onClick={this.signOut}>
              <i className="material-icons">exit_to_app</i>
            </button>
          </header>
        );
      case '/e':
        return (
          <header>
            <div>
              <Link to="/">
                <i className="material-icons">keyboard_arrow_left</i>
                <p>Inicio</p>
              </Link>
              <p className="title">Editar Examen</p>
            </div>
          </header>
        );
      case '/t':
        return (
          <header>
            <div>
              <Link to="/">
                <i className="material-icons">keyboard_arrow_left</i>
                <p>Inicio</p>
              </Link>
              <p className="title">Editar Equipo</p>
            </div>
          </header>
        );
      default:
        return
    }
  }

  signOut = () => {
    Firebase.authentication.signOut()
      .catch(error => displayError(error));
  }

  render() {
    const { user, ready } = this.state;
    if (user) {
      const { admin } = user.data();
      return (
        <BrowserRouter>
          <div id="Secret">
            <Route render={this.makeHeader} />
            <Switch>
              <Route exact path="/" render={() => <Home admin={admin} />} />
              <Route path="/e" component={Exam} />
              <Route path="/t" render={() => <Team id={user.id} />} />
              <Redirect to="/" />
            </Switch>
          </div>
        </BrowserRouter>
      );
    } else {
      return (
        <div id="Secret">
          <Auth ready={ready} />
        </div>
      );
    }
  }

}

export default Secret;
