import React from 'react';
import PropTypes from 'prop-types';

import './User.css';

class User extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    set: PropTypes.func.isRequired
  };

  render() {
    const { user, set } = this.props;
    const { id, name, opportunities } = user;
    return (
      <button className="User" onClick={() => set(user)}>
        <i className="material-icons">person_outline</i>
        <p className="name">{name}</p>
        <p className="id">ID. {id}</p>
        <p className="status">{opportunities} Oportunidad{opportunities !== 1 && 'es'}</p>
      </button>
    );
  }

}

export default User;
