import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import Button from '../../../shared/Button/Button';

import './Answerable.css';

class Answerable extends React.Component {

  static propTypes = {
    time: PropTypes.number.isRequired,
    n: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    audio: PropTypes.bool.isRequired,
    answer: PropTypes.func.isRequired,
    selected: PropTypes.object,
    next: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired
  };

  constructor() {
    super();

    this.state = {
      audio: null,
      played: false
    };
  }

  componentDidMount = () => {
    const { n, audio } = this.props;
    if (audio) {
      Firebase.storage.ref(`${n}.mp3`).getDownloadURL()
        .then(url => {
          const a = new Audio(url);

          a.addEventListener('canplaythrough', () => {
            this.setState({ audio: a });
          });
        })
        .catch(error => displayError(error));
    }
  }

  componentWillUnmount = () => {
    const { audio } = this.state;

    if (audio) {
      audio.pause();
    }
  }

  isLargeText = () => {
    const { question, audio } = this.props;
    if (audio) {
      return false;
    } else {
      return question.text.length > 100;
    }
  }

  select = i => {
    const { question, answer } = this.props;
    answer({ i, correct: i === question.answer });
  }

  isSelected = i => {
    const { selected } = this.props;
    if (selected) {
      return selected.i === i;
    } else {
      return false;
    }
  }

  defineReadableTime = () => {
    const { time } = this.props;
    const date = new Date(time);
    return `${date.getMinutes()}:${date.getSeconds()}`;
  }

  play = () => {
    const { audio } = this.state;

    this.setState({ played: true }, () => audio.play());
  }

  render() {
    const { n, question, audio, selected, next, saving } = this.props;
    const { audio: stateAudio, played } = this.state;

    return (
      <div id="Answerable">
        <header>
          <p>{question.instructions}</p>
          {audio ? <p>Question <span>{n}</span></p> : <p className={classNames({ large: this.isLargeText() })}><span>{n}</span> {question.text}</p>}
        </header>
        <main>
          {question.options.map((option, i) =>
            <button key={i} onClick={() => this.select(i)} disabled={saving}>
              <i className={`material-icons ${this.isSelected(i)}`}>{this.isSelected(i) ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
              <p>{option}</p>
            </button>
          )}
        </main>
        <footer>
          <div>
            <p><i className="material-icons">timelapse</i>{this.defineReadableTime()}</p>
            <p><i className="material-icons">help_outline</i>{n + (audio ? 0 : 50)} / 100</p>
          </div>
          {audio && (
              <Button blue title="Reproducir Audio" action={this.play} loading={!stateAudio} disabled={played} />
          )}
          <Button title="Siguiente" action={next} loading={saving} disabled={selected.i === null} />
        </footer>
      </div>
    );
  }

}

export default Answerable;
