import React from 'react';
import { Link } from 'react-router-dom';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';
import zipcelx from '../../../../model/Zipcelx';

import './Admin.css';

class Admin extends React.Component {

  constructor() {
    super();
    this.state = {
      loading: false
    }
  }

  downloadUserRecord = () => {
    this.setState({ loading: true }, () => {
      Firebase.firestore.collection('users').orderBy('registered', 'desc').get()
        .then(querySnapshot => {
          const rows = [];
          querySnapshot.forEach(doc => {
            const { registered } = doc.data();
            const registeredDate = new Date(registered);
            const row = [{
              value: `${registeredDate.getFullYear()}-${registeredDate.getMonth() + 1}-${registeredDate.getDate()}`,
              type: 'string'
            }];
            for (const key of [ 'name', 'id', 'email', 'corporateEmail', 'company', 'post', 'address', 'city', 'country', 'phone', 'executive' ]) {
              row.push({
                value: doc.get(key) || '—',
                type: 'string'
              });
            }
            rows.push(row);
          });
          zipcelx({
            filename: 'Usuarios',
            sheet: {
              data: [
                [{
                  value: 'Registro',
                  type: 'string'
                }, {
                  value: 'Nombre',
                  type: 'string'
                }, {
                  value: 'Número de Identificación',
                  type: 'string'
                }, {
                  value: 'Correo Personal',
                  type: 'string'
                }, {
                  value: 'Correo Corporativo',
                  type: 'string'
                }, {
                  value: 'Empresa',
                  type: 'string'
                }, {
                  value: 'Cargo',
                  type: 'string'
                }, {
                  value: 'Dirección',
                  type: 'string'
                }, {
                  value: 'Ciudad',
                  type: 'string'
                }, {
                  value: 'País',
                  type: 'string'
                }, {
                  value: 'Celular',
                  type: 'string'
                }, {
                  value: 'Ejecutivo',
                  type: 'string'
                }],
                ...rows
              ]
            }
          })
            .then(() => this.setState({ loading: false }));
        })
        .catch(error => displayError(error));
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div id="Admin">
        <Link to="/e">
          <i className="material-icons">chrome_reader_mode</i>
          <p>Editar Examen</p>
        </Link>
        {loading ?
          <p>Cargando...</p>
          :
          <Link to="" onClick={this.downloadUserRecord}>
            <i className="material-icons">table_chart</i>
            <p>Descargar Usuarios</p>
          </Link>
        }
        <Link to="/t">
          <i className="material-icons">account_circle</i>
          <p>Editar Equipo</p>
        </Link>
      </div>
    );
  }

}

export default Admin;
