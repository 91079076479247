import React from 'react';
import PropTypes from 'prop-types';

import AnswerSet from './AnswerSet/AnswerSet';

import './TestSection.css';

class TestSection extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    questions: PropTypes.object.isRequired
  };

  defineSet = (from, to) => {
    const { questions } = this.props;
    const set = {};
    for (let n = from; n <= to; n++) {
      set[n] = questions[n];
    }
    return set;
  }

  countCorrectAnwers = () => {
    const { questions } = this.props;
    let count = 0;
    for (let n of Object.keys(questions)) {
      if (questions[n].correct) {
        count++;
      }
    }
    return count;
  }

  render() {
    const { title } = this.props;
    return (
      <div className="TestSection">
        <p>{title}</p>
        <main>
          <AnswerSet set={this.defineSet(1, 10)} />
          <AnswerSet set={this.defineSet(11, 18)} />
          <AnswerSet set={this.defineSet(19, 26)} />
          <AnswerSet set={this.defineSet(27, 34)} />
          <AnswerSet set={this.defineSet(35, 42)} />
          <AnswerSet set={this.defineSet(43, 50)} />
        </main>
        <p>{this.countCorrectAnwers()} / 50</p>
      </div>
    );
  }

}

export default TestSection;
