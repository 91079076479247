import React from 'react';
import PropTypes from 'prop-types';

import './SetRow.css';

class SetRow extends React.Component {

  static propTypes = {
    n: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired
  };

  defineClassName = i => {
    const { value } = this.props;
    if (value.i === i) {
      if (value.correct) {
        return 'green';
      } else {
        return 'red';
      }
    } else {
      return 'gray';
    }
  }

  defineIconName = i => {
    const { value } = this.props;
    if (value.i === i) {
      if (value.correct) {
        return 'check_circle';
      } else {
        return 'cancel';
      }
    } else {
      return 'radio_button_unchecked';
    }
  }

  render() {
    const { n } = this.props;
    return (
      <div className="SetRow">
        <p>{n}</p>
        <i className={`material-icons ${this.defineClassName(0)}`}>{this.defineIconName(0)}</i>
        <i className={`material-icons ${this.defineClassName(1)}`}>{this.defineIconName(1)}</i>
        <i className={`material-icons ${this.defineClassName(2)}`}>{this.defineIconName(2)}</i>
      </div>
    );
  }

}

export default SetRow;
