import React from 'react';
import PropTypes from 'prop-types';

import './ResultSummary.css';

class ResultSummary extends React.Component {

  static propTypes = {
    answers: PropTypes.object.isRequired
  };

  countCorrectAnwersInSet = (from, to) => {
    const { answers } = this.props;
    let count = 0;
    for (let n = from; n <= to; n++) {
      if (answers.audioQuestions[n].correct) {
        count++;
      }
    }
    for (let n = from; n <= to; n++) {
      if (answers.textQuestions[n].correct) {
        count++;
      }
    }
    return count;
  }

  countCorrectAnwers = () => {
    const { answers } = this.props;
    let count = 0;
    for (let n of Object.keys(answers.audioQuestions)) {
      if (answers.audioQuestions[n].correct) {
        count++;
      }
    }
    for (let n of Object.keys(answers.textQuestions)) {
      if (answers.textQuestions[n].correct) {
        count++;
      }
    }
    return count;
  }

  render() {
    return (
      <div id="ResultSummary">
        <div>
          <div>
            <p>F2F STARTER</p>
            <p>{this.countCorrectAnwersInSet(1, 10)}</p>
          </div>
          <div>
            <p>MKLE</p>
            <p>{this.countCorrectAnwersInSet(11, 18)}</p>
          </div>
          <div>
            <p>F2FP</p>
            <p>{this.countCorrectAnwersInSet(19, 26)}</p>
          </div>
          <div>
            <p>MKLP</p>
            <p>{this.countCorrectAnwersInSet(27, 34)}</p>
          </div>
          <div>
            <p>F2FI</p>
            <p>{this.countCorrectAnwersInSet(35, 42)}</p>
          </div>
          <div>
            <p>MKLU</p>
            <p>{this.countCorrectAnwersInSet(43, 50)}</p>
          </div>
        </div>
        <p>{this.countCorrectAnwers()} / 100</p>
      </div>
    );
  }

}

export default ResultSummary;
