import React from 'react';
import PropTypes from 'prop-types';

import TestSection from './TestSection/TestSection';
import ResultSummary from './ResultSummary/ResultSummary';

import './TestResults.css';

class TestResults extends React.Component {

  static propTypes = {
    answers: PropTypes.object,
    loading: PropTypes.bool.isRequired
  };

  render() {
    const { answers, loading } = this.props;
    if (loading) {
      return (
        <div id="TestResults">
          <p>Cargando...</p>
        </div>
      );
    } else {
      if (answers) {
        return (
          <div id="TestResults">
            <TestSection title="Comprensión Auditiva" questions={answers.audioQuestions} />
            <TestSection title="Comprensión Escrita" questions={answers.textQuestions} />
            <ResultSummary answers={answers} />
          </div>
        );
      } else {
        return (
          <div id="TestResults">
            <p>No ha tomado el examen.</p>
          </div>
        );
      }
    }
  }

}

export default TestResults;
