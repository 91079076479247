import React from 'react';
import PropTypes from 'prop-types';

import Firebase from '../../../../model/Firebase';
import { displayError } from '../../../../model/helpers';

import Button from '../../../shared/Button/Button';

import './MemberForm.css';

class MemberForm extends React.Component {

  static propTypes = {
    close: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      pass: '',
      admin: false,
      loading: false
    }
  }

  submit = () => {
    const { close } = this.props;
    const { name, email, pass, admin } = this.state;
    this.setState({ loading: true }, () => {
      const createUser = Firebase.functions.httpsCallable('createUser');
      createUser({ name, email, pass, admin })
        .then(() => close())
        .catch(error => {
          displayError({ code: error.message });
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const { close } = this.props;
    const { name, email, pass, admin, loading } = this.state;
    return (
      <div id="MemberForm">
        <div className="form">
          <header>
            <p>Nuevo Miembro</p>
            <button onClick={close}>
              <i className="material-icons">close</i>
            </button>
          </header>
          <main>
            <input type="text" value={name} placeholder="Nombre Completo" onChange={e => this.setState({ name: e.target.value })} disabled={loading} />
            <input type="text" value={email} placeholder="Correo" onChange={e => this.setState({ email: e.target.value })} disabled={loading} />
            <input type="text" value={pass} placeholder="Contraseña" onChange={e => this.setState({ pass: e.target.value })} disabled={loading} />
            <button onClick={() => this.setState({ admin: !admin })} disabled={loading}>
              <i className="material-icons">{admin ? 'check_box' : 'check_box_outline_blank'}</i>
              <p>Permisos de Administrador.</p>
            </button>
          </main>
          <Button title="Registrar" action={this.submit} disabled={!(name && email && pass)} loading={loading} />
        </div>
      </div>
    );
  }

}

export default MemberForm;
